import React from "react"
import Modal from "../Modal"
import { useUserChangedStore } from "@/stores"
import IconButton from "../IconButton"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useAuth } from "@/providers/authContext"

const ForceUserChangedModal = () => {
  const { userChanged, setUserChanged } = useUserChangedStore()
  const { logout } = useAuth()

  const onClose = async () => {
    await logout()

    setUserChanged(null)
    router.push("/auth", undefined, { shallow: true })
  }

  const router = useCustomRouter()

  return (
    <Modal
      className=""
      isOpen={!!userChanged}
      closeOnOutsideClick={false}
      closeOnEscape={false}
      onClose={onClose}
      showCloseButton={false}
    >
      <p className="text-center text-lg text-atherGray-100">
        You have changed the email successfully. Please Sign In with the new email
      </p>
      <div className="mt-4 flex justify-center">
        <IconButton onClick={onClose} className="px-4 py-2">
          Sign In
        </IconButton>
      </div>
    </Modal>
  )
}

export default ForceUserChangedModal
